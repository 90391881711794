<template>
    <div class="full-page" title="">
        <div class="card bs-5 w-30r">
            <h2 style="color:#eb0061">Login</h2>
            <p class="lead">Login with your credentials</p>
            <b-form @success="loginClicked" @error="loginError" ref="loginForm" :save-url="loginUrl"
                    #default="{model, loading}">
                <validated-input icon="fa fa-user" label="Username" v-model="model.username"
                                 :disabled="loading" :rules="rules.username"/>
                <validated-input icon="fa fa-lock" label="Password" type="password" v-model="model.password"
                                 :disabled="loading" :rules="rules.password"/>
                <span v-if="errorMessage" v-html="errorMessage"></span>
                <btn icon="fa fa-lock" text="Login" loading-text="Validating..." size="block"
                     :disabled="loading" :loading="loading"/>
                <div class="row p-3">
                    <btn class="col ml-1" text="W Login" @click="setLogin('W')" size="xs" :disabled="loading"/>
                    <btn class="col ml-1" text="P Login" @click="setLogin('P')" size="xs" :disabled="loading"/>
                    <btn class="col ml-1" text="F Login" @click="setLogin('F')" size="xs" :disabled="loading"/>
                    <btn class="col ml-1" text="S Login" @click="setLogin('S')" size="xs" :disabled="loading"/>
                    <btn class="col ml-1" text="C Login" @click="setLogin('C')" size="xs" :disabled="loading"/>
                    <btn class="col ml-1" text="H Login" @click="setLogin('H')" size="xs" :disabled="loading"/>
                </div>
            </b-form>
        </div>
    </div>
</template>

<script>
import urls from '@/data/urls';
import { mapActions } from 'vuex';

export default {
    name : 'Login',
    data () {
        return {
            loginUrl     : urls.auth.login,
            errorMessage : '',
            rules        : {
                username : {
                    required : true
                },
                password : {
                    required : true
                }
            }
        };
    },
    methods : {
        setLogin (user) {
            this.$refs.loginForm.setModelValue('password', 'admin');
            if (user === 'W') {
                this.$refs.loginForm.setModelValue('username', 'warehouse');
            } else if (user === 'P') {
                this.$refs.loginForm.setModelValue('username', 'purchase');
            } else if (user === 'F') {
                this.$refs.loginForm.setModelValue('username', 'finance');
            } else if (user === 'S') {
                this.$refs.loginForm.setModelValue('username', 'sales');
            } else if (user === 'C') {
                this.$refs.loginForm.setModelValue('username', 'catalogue');
            } else if (user === 'H') {
                this.$refs.loginForm.setModelValue('username', 'hr');
            } else {

            }
        },
        ...mapActions(['setUser']),
        loginClicked (response) {
            if (response.data.error === false) {
                const redirectUrl = sessionStorage.getItem('redirectPath');
                this.setUser(response.data.user);
                this.$router.push(redirectUrl || { path : '/' });
            }
            localStorage.clear();
        },
        loginError () {
            this.errorMessage = 'Invalid Credentials';
        }
    }
};
</script>

<style>
.full-page {
    height: 100vh;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}
</style>
